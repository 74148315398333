<ngx-dropzone class="converter-wrapper main-area" (change)="onDrop($event)" disableClick *ngIf="(progress == 'start')">

      <img src="../../../assets/images/zip-icon.png" alt="">
      <div class="app-title">{{ content.appTitleText }}</div>
      <div class="or-container">
        <div class="line">

        </div>
        <p>
          or
        </p>
        <div class="line">

        </div>
      </div>
      <button class="button-standard" (click)="onClick()">
        Browse files <img src="../../../assets/images/file-icon.svg" alt="">
      </button>

    <input type="file" (change)="onChange()" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
  </ngx-dropzone>

  <div class="format main-area" *ngIf="(progress == 'format')" >
    <div class="files-box">
      <div class="file" *ngFor="let file of files; let i = index">
        <div class="filename">{{file.name}}</div>
        <img src="../../../assets/images/trash.svg" alt="" (click)="deleteFile(file.name)">
      </div>
    </div>
   
    <div class="buttons-wrapper">
      <button class="add-more-files"  (click)="onClick()">
        <img src="../../../assets/images/plus.svg" alt="">
        Add more files
      </button>
      <div class="bottom-buttons">
        <div class="files-dropdown" *ngIf="outputFormats.length > 0">
            <img class="select-arrow" src="../../../assets/images/chevron.svg">
            <mat-select #converFormat *ngIf="progress == 'format'" (valueChange)="selectedFormat = converFormat.value"
              [ngClass]="['button-standard', 'button-medium', 'text-center']" placeholder="Format">
              <mat-option *ngFor="let format of outputFormats" [value]="format">
                {{format.toUpperCase()}}
              </mat-option>
            </mat-select>

          </div>
        <button 
         class="button-standard button-medium button-transparent" 
         (click)="clearScreen('start')" 
         *ngIf="outputFormats.length == 0">
         Start over
        </button>
        <button 
         class="button-standard button-medium" 
         (click)="uploadFiles()" 
         *ngIf="outputFormats.length == 0">
         Extract
        </button>
        <button 
          [disabled]="!selectedFormat"
         class="button-standard button-medium" 
         (click)="uploadFiles()" 
         *ngIf="outputFormats.length > 0">
         Compress
        </button>
      </div>
    </div>
    
    <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" style="display:none;" />
  </div>
  
  <div class="progress main-area" *ngIf="progress == 'loading'">
    <div class="progress-status" #progressStatus *ngIf="outputFormats.length > 0">{{ content.spinnerMessageCompressText }}</div>
    <div class="progress-status" #progressStatus *ngIf="outputFormats.length == 0">{{ content.spinnerMessageExtractText }}</div>
    <img class="progress-animation" src="../../../assets/images/progress.gif"  alt="">
    <div class="cancel-progress" (click)="progress='cancel'">Cancel</div>
  </div>
  
  <div class="alert main-area" *ngIf="(progress == 'cancel')">
      <img src="../../../assets/images/warning.png" alt="">
      <div class="alert-text">Are you sure you want to cancel conversion?</div>
      <div class="bottom-buttons">
        <button  class="button-standard button-small" (click)="clearScreen('start')">Yes</button>
        <button  class="button-standard button-small button-transparent" (click)="progress='loading'">No</button>
      </div>
  </div>
  
  <div class="alert main-area" *ngIf="(progress == 'error-occured')">
    <div class="alert-container">
      <img src="../../../assets/images/warning.png" alt="">
      <div class="alert-text">
        Error occurred
        <div class="alert-subtext">Please, try again</div>
      </div>
      <button class="button-standard" (click)="clearScreen('start')">Start over</button>
    </div>
  </div>
  
  <div class="download main-area" *ngIf="(progress == 'finished')">
    <img src="../../../assets/images/success.gif" alt="">
    <div class="download-headline">
      Your file has been <br>
      downloaded
    </div>
    <div class="download-success">
      If you do not see the download, <span class="try-again" (click)="clearScreen('start')">try again.</span>
    </div>
    <button type="button" class="button-standard" (click)="clearScreen('start')">Start over</button>
  </div>
  