
  <div class="container">
<header class="header">

  <div class="header-left">
    <img src="../../../assets/images/48x48.png" />
    <h2>
      Zip Like a Pro
    </h2>
  </div>
  <p class="header-right">
    A Single Click Archive Manager
  </p>

</header>
</div>