import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from  '@angular/common/http';
import { catchError } from 'rxjs/operators'; 
import { environment } from '@environments/environment'
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private httpClient: HttpClient) {}


  caseCompress(files: File[], format: string) {
    const response = {error: null, subscribtion: null, rejected: []};
    response.subscribtion = this.compressFiles(files, format)
    return response
  }

  caseExtract(files: File[]) {
    const response = {error: null, subscribtion: null, rejected: []};
    response.subscribtion = this.extractFiles(files)
    return response
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage:string;

    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //return throwError(errorMessage);
    console.log(errorMessage)
    return errorMessage
  }

  private compressFiles(files: any, format: string) {
    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });

    return this.httpClient
    .post<any>(environment.service_url + environment.path.compress + format + '/' + files.length, formData)
    .pipe(catchError(this.handleError));
  }

  private extractFiles(files: any) {
    let formData: FormData = new FormData();
    
    Object.values(files).forEach((value:File, index)=> {
      formData.append(`file${index+1}`, value, value.name);
    });

    return this.httpClient
    .post<any>(environment.service_url + environment.path.extract, formData)
    .pipe(catchError(this.handleError));
  }
}