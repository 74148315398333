<div class="container">
    <div class="content">
        <p class="ads">
            Ads:
        </p>
        <a class="ads-link"
         *ngFor="let link of links"
          [href]="'https://searc.me/03289e8f-6d50-4ad1-806a-2a0708038a5d?q=' + link.href + '&chname=cel_ziplike'" 
          target="_blank">
            {{link.title}}
        </a>
    </div>
</div>