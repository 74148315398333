<div class="page-wrapper">
    <app-header>
  
    </app-header>
    <!-- <app-ads (linkClicked)="openSearchLink($event)">
  
    </app-ads> -->
    <h1 class="main-title">
      Compress or Extract Files
    </h1>
    <p class="main-description">
      Start Zipping Like a Pro
    </p>
    
    <div class="ads-row-wrapper">
      <a href="https://use.pdfconverterpower.com?utm_source=ziplike_banners" target="_blank" class="ads-banner">
        <img src="../../../assets/images/pdf-ads.gif" alt="">
      </a>
      <type-archive></type-archive>
      <a href="https://app.gifsmakerpro.com?utm_source=ziplike_banners" target="_blank" class="ads-banner">
        <img src="../../../assets/images/gifs-ads.png" alt="">
      </a>
    </div>
  
    <app-advantages>

    </app-advantages>
 
    <!-- <app-ads (linkClicked)="openSearchLink($event)">
  
    </app-ads> -->
    
    <app-why-choose>

    </app-why-choose>
    
  
  </div>
