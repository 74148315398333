import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-why-choose',
  templateUrl: './why-choose.component.html',
  styleUrls: ['./why-choose.component.scss', '../app/app.component.scss']
})
export class WhyChooseComponent {
 
}