import { Component, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { DataLayerService } from '@services/data-layer.service';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';

@Component({
  selector: 'type-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent {
  content = {
    appTitleText: 'Drag & Drop File Here',
    appGuidlineText: 'Drag files here or',
    chooseFilesButtonText: 'Choose files',
    spinnerMessageCompressText: 'Compressing...',
    spinnerMessageExtractText: 'Extracting...',
    successMessageCompressText: 'We have successfully compressed your files!',
    successMessageExtractText: 'We have successfully extracted your files!',
  }
  private _progress:string = 'start'
  private _selectedFormat:string;
  private progress_list = ['start','loading', 'format', 'cancel','error-occurred','finished']
  allowedFormats = ['zip', 'rar'];
  outputFormats = [];
  inProgress:boolean = false;
  files  = []
  sub: Array<Subscription> = [];
  @ViewChild('progressStatus', {static: false}) progressStatus: ElementRef
  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef


  constructor(
    private uploadService: UploadService, 
    private dataLayerService: DataLayerService,
    private _router:Router,
    private cookieService: CookieService) {
      this._router.events.subscribe(event=> {
        if (event instanceof NavigationEnd)
        {
            this.dataLayerService.logPageView(event.url);
        }
      });
  }
  
  uploadFiles() {
    this.progress = 'loading';
    let response;

    if(this.outputFormats.length > 0) {
      this.dataLayerService.logEvent('ga_event','click', 'compress', '');
      response = this.uploadService.caseCompress(this.files, this.selectedFormat);
    } else {
      this.dataLayerService.logEvent('ga_event','extract', 'extract', '');
      response = this.uploadService.caseExtract(this.files);
    }

    if(response.error) {
      this.clearScreen('error-occurred')
    } else {
      const s = response.subscribtion.subscribe(result => {
        console.log(result)
        if(this.progress == 'loading') {
          if (result['success'] == true) {
            this.downloadFile(result)
          } else {
            this.clearScreen('error-occurred')
          }
        }
      })
      this.sub.push(s)
    }
  }

  selectFormat() {
    if(this.files.length === 1) {
      const ext = this.files[0].name.split('.').pop().toLowerCase();
      if( ! this.allowedFormats.includes(ext)) {
        this.outputFormats = this.allowedFormats;
      }
    } else {
      this.outputFormats = this.allowedFormats;
    }
    this.progress = 'format'
  }

  
  deleteFile(filename: string) {
    console.log("delete");
    for (const [key, value] of Object.entries(this.files)) {
      if(value.name == filename) {
        const index = parseInt(key)
        if(this.files.length == 1) {
          this.clearScreen('start');
        } else {
          this.files.splice(index, 1);
        }
      }
    }
  }

  clearScreen(progress:string) {
    if(progress && this.progress_list.includes(progress)) {
      this.progress = progress
    }

    Object.values(this.sub).forEach((value:Subscription, index)=> {
      value.unsubscribe()
    })

    this.sub = []
    this.files = [];
    this.outputFormats = [];
    this.inProgress = false;
    this.selectedFormat = null;

    var elems = document.querySelectorAll(".format .btn-convert");
    for (var i = 0; i < elems.length; i++) {
      elems[i].classList.add('btn-disabled');
    }
  }

  set selectedFormat(val: string) {
    this._selectedFormat = val;
    if(val != "Select format") {
      var elems = document.querySelectorAll(".format .btn-convert");

      for (var i = 0; i < elems.length; i++) {
          elems[i].classList.remove('btn-disabled');
      }
    }
  }

  get selectedFormat(): string {
    return this._selectedFormat;
  }

  set progress(val: string) {
    this._progress = val;
    if(val == 'loading' &&  ! this.inProgress) {
      this.loading();
    }
  }

  get progress(): string {
    return this._progress;
  }

  private downloadFile(file) {
    const inIFrame = (window.location != window.parent.location);
    if (inIFrame) {
      window.parent.postMessage(
        {
          event_id: 'conversionComplete',
          params: {
            url: file["file"]["url"],
            name: file["file"]["filename"]
          }
        },
        "*"
      );
    }
    else {
      window.location = file["file"]["url"];
    }
    this.files = []
    this.progress = 'finished'
  }

  loading() {
    // progress bar for display only
    this.inProgress = true;
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
  }

  onChange() {
    const fileUpload = this.fileUpload.nativeElement;

    if(fileUpload.files.length > 0) {
      this.dataLayerService.logEvent('ga_event','click', 'Choose files', '');
      this.files.push(...fileUpload.files);
      this.selectFormat();
    }
  }

  onDrop(event) {
    if(event.addedFiles) {
      if(event.addedFiles.length > 0) {
        this.dataLayerService.logEvent('ga_event','click', 'Choose files', '');
        this.files.push(...event.addedFiles);
        this.selectFormat();
      }
    }
  }

  getCookie(key: string){
    return this.cookieService.get(key);
  }
}