import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent {
  links: any[] = [
  {title: 'Extract rar files', href: 'extract-rar-files', gaCategory: 'extract_rar_files'},
  {title: 'Extract Zip', href: 'extract-zip', gaCategory: 'extract_zip'},
  {title: 'Zip File Extract', href: 'zip-file-extract', gaCategory: 'zip_file_extract'},
  {title: 'How to Zip a file', href: 'how-to-zip-a-file', gaCategory: 'how_to_zip_a_file'},
  {title: 'Archive Extractor', href: 'archive-extractor', gaCategory: 'archive_extractor'},
  {title: 'Archive files', href: 'archive-files', gaCategory: 'archive_files'},]
  @Output() linkClicked = new EventEmitter();

    constructor( ) {

    }
   
}