<div class="container">
<h2 class="main-title">
    Best File Compression Software in 2022
</h2>
<p class="main-description">
    Zip like a pro is the most popular online Zip/Rar compression software. 
    <br>We help millions of happy users to compress or extract files easily every day.
</p>
<div class="advantages-container">

    <div class="advantages-row ">
        <div class="advantage-text">
            <h3>
                Quick and Simple Extraction
            </h3>
            <p>
                Decompress your archive after uploading it. 
                As soon as the archive has been unpacked, you will receive the download link.
            </p>
        </div>
        <img src="../../../assets/images/advantages1.png" alt="">
        
    </div>

    <div class="advantages-row">
        <div class="advantage-text">
            <h3>
                Quick and Simple Extraction
            </h3>
            <p>
                Decompress your archive after uploading it. 
                As soon as the archive has been unpacked, you will receive the download link.
            </p>
        </div>
        <img src="../../../assets/images/advantages2.png" alt="">
        
    </div>

    <div class="advantages-row ">
        <div class="advantage-text">
            <h3>
                Unpack from any location
            </h3>
            <p>
                All operating systems, including Windows, Linux, Mac OS, Android, and iOS, can use it. 
                On our servers, everything is decompressed. You don't need to install any plugin.
            </p>
        </div>
        <img src="../../../assets/images/advantages3.png" alt="">
        
    </div>
</div>
</div>