import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Zip Like A Pro App';

  constructor(private titleService: Title) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {}

  openSearchLink(title) {
    window.open(
      `https://searc.me/03289e8f-6d50-4ad1-806a-2a0708038a5d?q=${title}&chname=cel_`,
      '_blank'
    );
  }
}
