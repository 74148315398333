import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from '@app/components/app/app.component';
import { ArchiveComponent } from '@app/components/archive/archive.component';
import { UploadService } from '@services/upload.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AdsComponent } from '@app/components/ads/ads.component';
import { HeaderComponent } from '@app/components/header/header.component';
import { AdvantagesComponent } from '@app/components/advantages/advantages.component';
import { WhyChooseComponent } from '@app/components/why-choose/why-choose.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [
    AppComponent,
    ArchiveComponent,
    AdsComponent,
    HeaderComponent,
    AdvantagesComponent,
    WhyChooseComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxDropzoneModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    NoopAnimationsModule,
    MatSelectModule
  ],
  providers: [UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
