<h2 class="main-title">
    Why Choose Zip Like a Pro?
</h2>
<div class="why-choose-wrapper">
    <div class="why-choose-element">
        <img src="../../../assets/images/why-choose1.png" alt="">
        <h4>
            24/7 Anywhere Access
        </h4>
        <p>
            At home? On the road? 
            Our app makes it a cinch to extract or compress multiple files from both mobile and desktop devices. 
            Compatible with all web browsers.
        </p>
    </div>
    <div class="why-choose-element">
        <img src="../../../assets/images/why-choose2.png" alt="">
        <h4>
            Customer Support
        </h4>
        <p>
            Our customers receive prompt, courteous service. First of all, our blog has a ton of solutions and instructions.
            In addition, our email box is open for inquiries and help.
        </p>
    </div>
    <div class="why-choose-element">
        <img src="../../../assets/images/why-choose3.png" alt="">
        <h4>
            Free Downloads
        </h4>
        <p>
            Our users have the ability to download as many files as they want. 
            Yes yes, you heard right, the amount of downloads is unlimited, and it's free!
             You can extract or compress files in seconds.
        </p>
    </div>
</div>